import Vue from "vue";
import VueRouter from "vue-router";
// import UserMap from "@/views/GaoMap/UserMap"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
    component: () => import("@/layout"),
    // eslint-disable-next-line no-sparse-arrays
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("../views/home"),
      },
      {
        path: "/UserMap",
        name: "UserMap",
        component: () => import("../views/GaoMap/UserMap"),
      },
      {
        path: "/user",
        name: "user",
        component: () => import("../views/user"),
      },
      
      {
        path: "/serviceCenter",
        name: "serviceCenter",
        component: () => import("../views/user/serviceCenter"),
      },
      {
        path: "/merchandiseAdd",
        name: "merchandiseAdd",
        component: () => import("../views/project/merchandiseAdd"),
      },
      {
        path: "/commodity",
        name: "commodity",
        component: () => import("../views/project/commodity"),
      },
      {
        path: "/species",
        name: "species",
        component: () => import("../views/project/species"),
      },
      {
        path: "/shop",
        name: "shop",
        component: () => import("../views/project/ShopInformation/shop"),
      },
      {
        path: "/coupon",
        name: "coupon",
        component: () => import("../views/project/coupon"),
      },
      {
        path: "/couponUser",
        name: "couponUser",
        component: () => import("../views/project/couponUser"),
      },
      {
        path: "/ShopOrder",
        name: "ShopOrder",
        component: () => import("../views/project/ShopOrder"),
      },
      {
        path: "/order",
        name: "order",
        component: () => import("../views/project/order"),
      },
      {
        path: "/flow",
        name: "flow",
        component: () => import("../views/project/flow"),
      },
      {
        path: "/storeAddOne",
        name: "storeAddOne",
        component: () => import("../views/project/ShopInformation/storeAddOne"),
      },
      {
        path: "/storeAddTwo",
        name: "storeAddTwo",
        component: () => import("../views/project/ShopInformation/storeAddTwo"),
      },
      {
        path: "/StoreInformation",
        name: "StoreInformation",
        component: () => import("../views/project/ShopInformation/StoreInformation"),
      },
      {
        path: "/variety",
        name: "variety",
        component: () => import("../views/project/variety"),
      },
      {
        path: "/matrix",
        name: "matrix",
        component: () => import("../views/base/matrix"),
      },
      {
        path: "/baseAddOne",
        name: "baseAddOne",
        component: () => import("../views/base/baseAddOne"),
      },
      {
        path: "/baseCommodity",
        name: "baseCommodity",
        component: () => import("../views/base/baseCommodity"),
      },
      {
        path: "/commodityAdd",
        name: "commodityAdd",
        component: () => import("../views/base/commodityAdd"),
      },
      {
        path: "/CommoditySize",
        name: "CommoditySize",
        component: () => import("../views/project/CommoditySize"),
      },
      {
        path: "/CommoditySizeChange",
        name: "CommoditySizeChange",
        component: () => import("../views/project/CommoditySizeChange"),
      },
      {
        path: "/commodityScale",
        name: "commodityScale",
        component: () => import("../views/base/commodityScale"),
      },
      {
        path: "/specification",
        name: "specification",
        component: () => import("../views/base/specification"),
      },
      {
        path: "/specificationChange",
        name: "specificationChange",
        component: () => import("../views/base/specificationChange"),
      },
      {
        path: "/monitoring",
        name: "monitoring",
        component: () => import("../views/base/monitoring"),
      },
      {
        path: "/slideshow",
        name: "slideshow",
        component: () => import("../views/project/slideshow"),
      },
      {
        path: "/PointsMerchandise",
        name: "PointsMerchandise",
        component: () => import("../views/integration/PointsMerchandise"),
      },
      {
        path: "/PointcomAdd",
        name: "PointcomAdd",
        component: () => import("../views/integration/PointcomAdd"),
      },
      {
        path: "/PointcomLook",
        name: "PointcomLook",
        component: () => import("../views/integration/PointcomLook"),
      },
      {
        path: "/categoryShow",
        name: "categoryShow",
        component: () => import("../views/category/categoryShow"),
      },
      {
        path: "/ShopCategory",
        name: "ShopCategory",
        component: () => import("../views/category/ShopCategory"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login"),
  },
  
];

const router = new VueRouter({
  routes,
});


export default router;
