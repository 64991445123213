<template>
    <div class="tooltip">
      <el-tooltip effect="dark" placement="right">
        <div slot="content">
          <p v-for="(item, index) in messages" :key="index">{{item}}</p>
        </div>
        <i class="el-icon-info" style="color:#409eff;margin-left:5px;"></i>
      </el-tooltip>
    </div>
  </template>
  <script>
    export default {
      name: 'promptMessages',
      data() {
        return {};
      },
      props: {
        messages: {
          type: Array,
          default() {
            return [];
          }
        }
      }
    };
  </script>
  