import '@/utils/components.js'; // 自定义组件 js
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "./styles.scss";
import "@/assets/base.css";
import "./utils/filters";
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;
Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
