import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios'
Vue.prototype.$axios = axios;  //写成原型属性，

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  getters: {},
  mutations: {
		
  },
  actions: {
  },
  modules: {
    
  }
});
